<template>
  <div>
    <div v-if="eventInfo && eventInfo.id && (!eventInfo.shiftType || (eventInfo.shiftType != 'Permanent' && eventInfo.shiftType != 'Job'))">
      <Loader v-if="(performingRequest && 
      (!this.eventAssignmentsByDay || (this.eventAssignmentsByDay && this.eventAssignmentsByDay.length == 0))
       )" />
    </div>
    <div v-else>
      <Loader v-if="(performingRequest && 
      (!this.eventAssignments || (this.eventAssignments && this.eventAssignments.length == 0))
       )" />

      
    </div>
    


    <div class="dashboard__container pt-3 pb-0" v-if="eventInfo && eventInfo.id && (!eventInfo.shiftType || (eventInfo.shiftType != 'Permanent' && eventInfo.shiftType != 'Job'))">

      <div class="whiteBack mb-3" style="width:50%; min-width: 20rem;">
        <h5 class="mb-2" v-if="!showAll">Choose Day:</h5>

        <v-select
          v-if="!showAll"
          label="title" 
          :options="eventShiftsDays"
          clearable
          :id="`pickDay` + eventInfo.id"
          v-model="activeDay"
          @input="updateDay()"
          >
        </v-select>
        <div class="flex justify-space-between">
          <button class="btn btn__outlined btn__small mt-4" @click="showAllSheets()" v-if="!showAll">Show All</button>
          <button class="btn btn__outlined btn__small mt-4" @click="hideAllSheets()" v-if="showAll">Hide All</button>

          <button v-if="!isShowAddRows" class="btn btn__small btn__outlined mr-3 mt-4" @click="showAddRows()">Add Rows <i class="fa-regular fa-plus ml-2"></i></button>
          <button v-if="isShowAddRows" class="btn btn__small btn__dark mr-3 mt-4" @click="hideAddRows()">Hide Adding Rows <i class="fa-regular fa-plus ml-2"></i></button>
        </div>
      </div>
    </div>


    <div class="dashboard__container pb-0 pt-3" v-if="eventInfo && eventInfo.id && ((eventInfo.shiftType && (eventInfo.shiftType == 'Permanent' || eventInfo.shiftType == 'Job')) || showAll)">
      <div class="flex flex-wrap justify-space-between mb-3" style="width:100%;">
        <span>
          <button v-if="!isShowDateFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': (assignmentsByDate && assignmentsByDate.length >= 1), 'btn__outlined': (!assignmentsByDate || assignmentsByDate.length == 0) }" @click="showDateFilter()">Date Filter <i class="fa-regular fa-sliders ml-2"></i></button>
          <button v-if="isShowDateFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': (assignmentsByDate && assignmentsByDate.length >= 1), 'btn__dark': (!assignmentsByDate || assignmentsByDate.length == 0) }" @click="hideDateFilter()">Hide Date Filter <i class="fa-regular fa-sliders ml-2"></i></button>

          <button v-if="!isShowShiftFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': (assignmentsByShift && assignmentsByShift.length >= 1), 'btn__outlined': (!assignmentsByShift || assignmentsByShift.length == 0) }" @click="showShiftFilter()">Shift Filter <i class="fa-regular fa-sliders ml-2"></i></button>
          <button v-if="isShowShiftFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': (assignmentsByShift && assignmentsByShift.length >= 1), 'btn__dark': (!assignmentsByShift || assignmentsByShift.length == 0) }" @click="hideShiftFilter()">Hide Shift Filter <i class="fa-regular fa-sliders ml-2"></i></button>

          <button v-if="!isShowUserFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': (assignmentsByUser && assignmentsByUser.length >= 1), 'btn__outlined': (!assignmentsByUser || assignmentsByUser.length == 0) }" @click="showUserFilter()">User Filter <i class="fa-regular fa-sliders ml-2"></i></button>
          <button v-if="isShowUserFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': (assignmentsByUser && assignmentsByUser.length >= 1), 'btn__dark': (!assignmentsByUser || assignmentsByUser.length == 0) }" @click="hideUserFilter()">Hide User Filter <i class="fa-regular fa-sliders ml-2"></i></button>

          <button v-if="!isShowAddRows" class="btn btn__small btn__outlined mr-3" @click="showAddRows()">Add Rows <i class="fa-regular fa-plus ml-2"></i></button>
          <button v-if="isShowAddRows" class="btn btn__small btn__dark mr-3" @click="hideAddRows()">Hide Adding Rows <i class="fa-regular fa-plus ml-2"></i></button>

        </span>
        <span>
          <button class="btn btn__outlined btn__small mr-3 mb-3" @click="getDynamicUsers()">Load Dynamic Workers</button>
          <button class="btn btn__outlined btn__small mr-3 mb-3" @click="getUsers()">Load Static Workers</button>
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isVisible, 'btn__outlined': !isVisible }" @click="showVisiblePerm()">Visible</button>
         
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isHidden, 'btn__outlined': !isHidden }" @click="showHiddenPerm()">Hidden</button>
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isPaid, 'btn__outlined': !isPaid }" @click="showPaidPerm()">Paid</button>

         
        </span>

      </div>
    </div>

    <transition name="fadeStop">
      <div class="dashboard__container pb-0 pt-0" v-if="isShowUserFilter">
        <h4>Filter User:</h4>
        <div class="flex flex-row flex-wrap mt-3 mb-5" v-if="uniqueGroupUsers && uniqueGroupUsers.length >= 1">
          <div class="whiteBack" style="width:20%; min-width: 28rem;">
            <v-select
              label="lastName"
              :options="uniqueGroupUsers"
              clearable
              v-model="filteredUser"
              >
              
              <template #option="option">
                <span v-if="option.firstName && option.lastName"></span>
                {{option.firstName}} {{option.lastName}}
              </template>
              <!-- @input="filterShift()" -->
            </v-select>
          </div>
          <div class="ml-5 mb-3" v-if="filteredUser">
            <button class="btn btn__dark btn__small" @click="clearUser()">Clear User</button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fadeStop">
      <div class="dashboard__container pb-0 pt-0" v-if="isShowShiftFilter">
        <h4>Filter Shifts:</h4>
        <div class="flex flex-row flex-wrap mt-3 mb-5">
          <div class="whiteBack" style="width:20%; min-width: 28rem;">
            <v-select
              label="name" 
              :options="eventShifts"
              clearable
              v-model="filteredShift"
              
              >
             <!--  <template #option="option">
                Monday: {{option.mondayStart}} - {{option.mondayEnd}} | {{option.event}}
              </template>
              <template #option="option" v-if="option.title">
                {{option.title}}
              </template> -->
              <!-- @input="filterShift()" -->
            </v-select>
          </div>
          <div class="ml-5 mb-3" v-if="filteredShift">
            <button class="btn btn__dark btn__small" @click="clearShift()">Clear Shift</button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fadeStop">
      <div class="dashboard__container pb-0 pt-0" v-if="isShowDateFilter">
        <h4>Filter Dates:</h4>
        <div class="flex flex-row flex-wrap mt-3 mb-5">
          <div class="whiteBack">
            <label for="start">Start Date</label>
            <input type="date" v-model="startDate" />
          </div>
          <div class="whiteBack ml-5">
            <label for="end">End Date</label>
            <input type="date" v-model="endDate" />
          </div>
          <div class="ml-5 mb-3" v-if="endDate && startDate">
            <button class="btn btn__dark btn__small" @click="clearDates()">Clear Dates</button>
          </div>
          
        </div>
      </div>
    </transition>

    <transition name="fadeStop">
      <div class="dashboard__container pb-0 pt-0" v-if="isShowAddRows">
        <h4>Add Rows:</h4>
        <div class="flex flex-row flex-wrap">
          <div class="flex mt-3">
            <button class="chipDark mr-2 mb-2" v-for="(item, index) in shiftDates" @click="removeDay(index)">{{item}} <i class="fa-regular fa-xmark"></i></button>
          </div>
        </div>
        <div class="flex flex-row flex-wrap">
          <div class="whiteBack mb-5" style="width:20%; min-width: 18rem;">
            <div class="mb-3">
              <label for="pickDate">Select Dates:</label>
              <input type="date" v-model="newShiftDate" id="pickDate" @input="onPickDate()" />
            </div>
          </div>
          <!-- <div class="whiteBack mb-5" style="width:20%; min-width: 12rem;">
            <div class="mb-3">
              <label for="row">Date:</label>
              <input type="date" v-model="newRow.date" />
            </div>
          </div> -->
          <div class="whiteBack mb-5 ml-3" style="width:20%; min-width: 18rem;">
            <div class="mb-3" v-if="(uniqueGroupUsers && uniqueGroupUsers.length >= 1)">
              <h5 >Worker:</h5>
              <v-select
  
                label="lastName" 
                :options="uniqueGroupUsers"
                clearable
                multiple
                taggable
                v-model="newRow.users"
                >
                <template #option="option">
                  {{option.firstName}} {{option.lastName}} {{option.userId}}
                </template>
              </v-select>
            </div>
          </div>
          <div class="whiteBack mb-5 ml-3" style="width:32%; min-width: 28rem;">
            <div class="mb-3" v-if="(permShifts && permShifts.length >= 1)">
              <h5>Shift:</h5>
              <v-select

                label="name"
                :options="permShifts"
                clearable
                v-model="newRow.shift"
                >
                <template #option="option">
                  {{option.name}} - {{option.day}}
                </template>
              </v-select>
            </div>
            <div class="mb-3" v-if="(!permShifts || permShifts.length == 0) && (eventShifts && eventShifts.length >= 1)">
              <label>Shift:</label>
              <v-select
            
                :options="eventShifts"
                clearable
                v-model="newRow.shift"
                >
                <template #option="option">
                  {{option.name}} - {{option.day}}
                </template>
              </v-select>
            </div>
          </div>
          <div class="mb-5 ml-3" v-if="newRow.shift && newRow.users && newRow.users.length >= 1 && shiftDates && shiftDates.length >= 1">
            <button class="btn btn__primary btn__small" @click="buildRows()">Add Rows</button>
          </div>
        </div>
      </div>
    </transition>

    <div class="dashboard__container pt-3" v-if="eventInfo && eventInfo.id && (eventInfo.shiftType && (eventInfo.shiftType == 'Permanent' || eventInfo.shiftType == 'Job'))">
      
      <div class="dashboard__container--body">

        <PayrollTable v-if="isVisible && (!filteredAssignments || filteredAssignments.length == 0) && !isHidden" :eventAssignments="allVisible" />

        <PayrollTable v-if="(filteredAssignments && filteredAssignments.length >= 1) && !isHidden" :eventAssignments="filteredAssignments" />

        <PayrollTableAlt v-if="isHidden && allHidden && allHidden.length >= 1" :eventInfo="eventInfo" :eventAssignments="allHidden" :hidden="true" />

        <PayrollTable v-if="isPaid" :eventInfo="eventInfo" :eventAssignments="allPaid"  />

      </div>

    </div>






    <div class="dashboard__container pt-3" v-if="eventInfo && eventInfo.id && (!eventInfo.shiftType || (eventInfo.shiftType != 'Permanent' && eventInfo.shiftType != 'Job'))">
      

      <div class="dashboard__container--body"  v-if="showAll">

        <PayrollTable v-if="(filteredAssignments && filteredAssignments.length >= 1) && !isHidden" :eventAssignments="filteredAssignments" />

        <PayrollTable v-if="isVisible && (!filteredAssignments || filteredAssignments.length == 0)" :eventInfo="eventInfo" :eventAssignments="visibleAssignments" />

        <PayrollTableAlt v-if="isHidden" :eventInfo="eventInfo" :eventAssignments="hiddenAssignments" :hidden="true" />

        <PayrollTable v-if="isPaid" :eventInfo="eventInfo" :eventAssignments="paidAssignments" :hidden="false" />

      </div>

      <div class="dashboard__container--body"  v-if="activeDay && !showAll">

        <div class="text-right mb-3" style="width:100%;">
              
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isVisibleByDay, 'btn__outlined': !isVisibleByDay }" @click="showVisibleByDay()">Visible</button>
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isPaidByDay, 'btn__outlined': !isPaidByDay }" @click="showPaidByDay()">Paid</button>
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isHiddenByDay, 'btn__outlined': !isHiddenByDay }" @click="showHiddenByDay()">Hidden</button>
          <!-- <button class="btn btn__small btn__outlined mr-3 mb-3" @click.prevent="exportRegisterByDay()">Day Billing Export<i class="fas fa-external-link ml-3"></i></button> -->
        </div>

        <PayrollTable v-if="isVisibleByDay" :eventInfo="eventInfo" :eventAssignments="visibleAssignmentsByDay" />

        <PayrollTableAlt :hidden="true" v-if="isHiddenByDay" :eventInfo="eventInfo" :eventAssignments="hiddenAssignmentsByDay" />

        <PayrollTable :hidden="false" v-if="isPaidByDay" :eventInfo="eventInfo" :eventAssignments="paidAssignmentsByDay" />

      </div>
    </div>
  </div>
</template>

<style scoped>
  .vgt-selection-info-row {
    height: 3.28rem;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
// import ExportService from "@/services/ExportService"
// import TimesheetNote from '@/components/Timesheets/TimesheetNote.vue'
import PayrollTable from '@/components/PayrollTable.vue'
import PayrollTableAlt from '@/components/PayrollTableAlt.vue'  
import firebase from 'firebase/app';
// import * as moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['eventInfo', 'eventShifts', 'eventShiftsDays'],
  name: 'eventTimesheets',
  data: () => ({
    newShiftDate: "",
    shiftDates: [],
    isShowAddRows: false,
    isShowDateFilter: false,
    isShowShiftFilter: false,
    isShowUserFilter: false,
    performingRequest: false,
    showAll: false,
    isVisible: true,
    isHidden: false,
    isPaid: false,
    isVisibleByDay: true,
    isHiddenByDay: false,
    isPaidByDay: false,
    activeItem: null,
    activeDay: '',
    endDate: null,
    startDate: null,
    filteredShift: null,
    filteredUser: null,
    newRow: {
      // date: null,
      // user: null,
      shift: null,
      users: [],
    },
  }),
  // mounted () {
  //   if (this.eventAssignments || (this.eventAssignments && this.eventAssignments.length === '0')) {
  //     this.$store.dispatch("getEventAssignments", this.$route.params.id)
  //   }
  //   // this.$store.dispatch("getEventAssignments", this.$route.params.id)
  // },
  computed: {
    ...mapState(['eventAssignments', 'eventAssignmentsByDay', 'currentUser', 'eventGroupUsers']),
    uniqueGroupUsers() {
      // return Array.from(new Set(this.eventGroupUsers));
      if (this.eventGroupUsers && this.eventGroupUsers.length > 0) {

      const uniqueGroup = this.eventGroupUsers.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.userId === value.userId
        ))
      )

      let uniqueArray = [...new Set(uniqueGroup)];

      // const filteredUnique = uniqueGroup.filter(item => {
      //   return !item.hidden
      // })
      return uniqueArray

      // return this.eventGroupUsers.filter((item,
      //   index) => this.eventGroupUsers.indexOf(item) === index);
      // // let uniqueArray = [...new Set(this.eventGroupUsers)];
      // // let uniqueArray = []
      // // this.eventGroupUsers.forEach(item => {
      // //   if (!uniqueArray.includes(item)) {
      // //     uniqueArray.push(item);
      // //   }
      // // })
      // return uniqueArray
      } else {
        return null
      }
    },
    permShifts() {
      if (this.eventShifts && this.eventShifts.length > 1) {
        return this.eventShifts.filter(shift => {
          return (shift.type == 'Permanent' || shift.type == 'Job')
        })
      } else {
        return []
      }
    },
    allVisible() {
      if (this.eventAssignments && this.eventAssignments.length > 1) {
       return this.eventAssignments.filter(item => {
          return ((!item.hidden || item.hidden != true) && (!item.paystatus === 'paid' || item.paystatus != 'paid'))
        })
      } else {
        return []
      }
    },
    allHidden() {
      if (this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return (item.hidden)
        })
      } else {
        return []
      }
    },
    allPaid() {
      if (this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return ((!item.hidden || item.hidden != true) && (item.paystatus && item.paystatus === 'paid'))
        })
      } else {
        return []
      }
    },
    visibleAssignments() {
      if (this.showAll && this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return ((!item.hidden || item.hidden != true) && (!item.paystatus || item.paystatus != 'paid'))
        })
      } else {
        return []
      }
    },
    hiddenAssignments() {
      if (this.showAll && this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return (item.hidden)
        })
      } else {
        return []
      }
    },

    paidAssignments() {
      if (this.showAll && this.eventAssignments && this.eventAssignments.length > 0) {
        return this.eventAssignments.filter(item => {
          return (item.paystatus === 'paid')
        })
      } else {
        return []
      }
    },


    visibleAssignmentsByDay() {
      if (this.isVisibleByDay && this.activeDay && !this.showAll && this.eventAssignmentsByDay && this.eventAssignmentsByDay.length > 0) {
        return this.eventAssignmentsByDay.filter(item => {
          return ((!item.hidden || item.hidden != true) && (!item.paystatus || item.paystatus != 'paid') && item.day == this.activeDay)
        })
      } else {
        return []
      }
    },
    hiddenAssignmentsByDay() {
      if (this.isHiddenByDay && this.activeDay && !this.showAll && this.eventAssignmentsByDay && this.eventAssignmentsByDay.length > 0) {
        return this.eventAssignmentsByDay.filter(item => {
          return (item.hidden && item.day == this.activeDay)
        })
      } else {
        return []
      }
    },

    paidAssignmentsByDay() {
      if (this.isPaidByDay && this.activeDay && !this.showAll && this.eventAssignmentsByDay && this.eventAssignmentsByDay.length > 0) {
        return this.eventAssignmentsByDay.filter(item => {
          return (item.paystatus == 'paid' && item.day == this.activeDay)
        })
      } else {
        return []
      }
    },
    assignmentsByDate() {
      if (this.startDate && this.endDate && this.allVisible && this.allVisible.length > 0) {
        let startDate = new Date(this.startDate);
        let endDate = new Date(this.endDate);
      
        return this.allVisible.filter(item => {
          let eventDate = new Date(item.date)
          return ((eventDate >= startDate) && (eventDate <= endDate)) 
        })
      } else {
        return []
      }
    },
    assignmentsByShift() {
      if (this.filteredShift && this.allVisible && this.allVisible.length > 0) {
        return this.allVisible.filter(item => {
          return item.shiftId == this.filteredShift.id
        })
      } else {
        return []
      }
    },
    assignmentsByUser() {
      if (this.filteredUser) {
        return this.eventGroupUsers.filter(item => {
          return item.userId == this.filteredUser.userId
        })
      } else {
        return []
      }
    },
    filteredAssignments() {
      if (this.assignmentsByUser || this.assignmentsByShift || this.assignmentsByDate) {
        let usersArray = this.assignmentsByUser || []
        let shiftsArray = this.assignmentsByShift || []
        let datesArray = this.assignmentsByDate || []
        // let newArray = [...usersArray, ...shiftsArray, ...datesArray];
        // const newArray = this.assignmentsByUser.concat(this.assignmentsByShift, this.assignmentsByDate);
        // return newArray
        let shiftUserArray
        let shiftUserDateArray

        if ((usersArray && usersArray.length >= 1) && (shiftsArray && shiftsArray.length >= 1)) {
          shiftUserArray = usersArray.filter(element => shiftsArray.includes(element));
        } else if ((usersArray && usersArray.length >= 1) && (!shiftsArray || shiftsArray.length == 0)) {
          shiftUserArray = usersArray
        } else if ((!usersArray || usersArray.length == 0) && (shiftsArray && shiftsArray.length >= 1)) {
          shiftUserArray = shiftsArray
        } else {
          shiftUserArray = []
        }

        if ((shiftUserArray && shiftUserArray.length >= 1) && (datesArray && datesArray.length >= 1)) {
          shiftUserDateArray = shiftUserArray.filter(element => datesArray.includes(element));
        } else if ((shiftUserArray && shiftUserArray.length >= 1) && (!datesArray || datesArray.length == 0)) {
          shiftUserDateArray = shiftUserArray
        } else if ((!shiftUserArray || shiftUserArray.length == 0) && (datesArray && datesArray.length >= 1)){
          shiftUserDateArray = datesArray
        } else {
          shiftUserDateArray = []
        }
        return shiftUserDateArray
      }
    }
  },
  components: {
    Loader,
    PayrollTable,
    PayrollTableAlt
  },
  // mounted() {
  //   this.$store.dispatch("getEventAssignments", this.$route.params.id)
  // },
  methods: {
    async getDynamicUsers() {
      this.performingRequest = true
      await this.$store.dispatch("clearStaticEventAssignments")
      this.$store.dispatch("getEventAssignments", this.$route.params.id)
      this.performingRequest = false
    },
    async getUsers() {
      this.performingRequest = true
      await this.$store.dispatch("clearEventAssignments")
      this.$store.dispatch("getStaticEventAssignments", this.$route.params.id)
      this.performingRequest = false
    },
    removeDay(index) {
      this.shiftDates.splice(index, 1);
    },
    onPickDate() {
      this.shiftDates.push(this.newShiftDate)
      this.newShiftDate = ''
    },

    // async lockJob(props, shift) {
    //   props.row.status = "spinning"
    //   var lockJob = firebase.functions().httpsCallable('lockJob');
    //   await lockJob({
    //     event: this.event,
    //     day: this.activeDay,
    //     row: props.row,
    //     shift: shift,
    //   })
    //   .then((result) => {
    //     props.row.status = "assigned"
    //   })
    // },

    async buildRows() {
      console.log(this.newRow)
      this.performingRequest = true
      let matrix = []
      await this.newRow.users.forEach(user => {
        this.shiftDates.forEach(date => {
          matrix.push({
            user: user,
            date: date
          })
        })
      })
      return this.addRows(matrix)
      
    },
    async addRows(matrix) {
      console.log(matrix)
      await matrix.forEach(item => {
        console.log(item)
        let lockJob
        if (this.eventInfo && this.eventInfo.id && (!this.eventInfo.shiftType || (this.eventInfo.shiftType != 'Permanent' && this.eventInfo.shiftType != 'Job'))) {
          lockJob = firebase.functions().httpsCallable('lockShift');
        } else {
          lockJob = firebase.functions().httpsCallable('lockJob');
        }
        
        let fullName = item.user.firstName + ' ' + item.user.lastName
        item.user.realId = item.user.id
        item.user.fullName = fullName
        lockJob({
          event: this.eventInfo,
          day: item.date,
          row: item.user,
          shift: this.newRow.shift,
          sendPlacementNotifications: false
        })
      })

      this.newRow = {}
      this.newShiftDate = null,
      this.shiftDates = [],
      this.performingRequest = false
    },
    showAllSheets() {
      this.showAll = true
      this.activeDay = ''
      this.$store.dispatch("clearEventAssignmentsByDay")
      if (!this.eventAssignments || this.eventAssignments.length < 1) {
        this.$store.dispatch("getEventAssignments", this.$route.params.id)
      }
      // this.$store.dispatch("getEventAssignments", this.$route.params.id)
    },
    hideAllSheets() {
      this.$store.dispatch('clearEventAssignments')
      this.showAll = false
       this.isShowUserFilter = false
      this.isShowDateFilter = false
      this.isShowShiftFilter = false
      this.isShowAddRow = false
      // this.$store.dispatch("clearEventAssignments")
    },
    updateDay() {
      this.$store.dispatch('clearEventAssignmentsByDay')
      this.performingRequest = true
      this.isShowShiftFilter = false
      this.isShowDateFilter = false
      this.isShowUserFilter = false
      this.isShowAddRows = false
      if (this.activeDay) {
        this.$store.dispatch("getEventAssignmentsByDay", {
          eventId: this.eventInfo.id,
          day: this.activeDay
        })
      } else {
        this.$store.dispatch('clearEventAssignmentsByDay')
      }
      // setTimeout(() => {
      //   this.performingRequest = false
      // }, 2000)
    },
    showUserFilter() {
      if (!this.eventGroupUsers || this.eventGroupUsers.length == 0) {
        this.$store.dispatch("getEventGroupUsers", this.$route.params.id)
      }
      this.isShowUserFilter = true
      this.isShowDateFilter = false
      this.isShowShiftFilter = false
      this.isShowAddRows = false
      this.isHidden = false
      this.isVisible = true
    },
    hideUserFilter() {
      this.isShowUserFilter = false
      this.$store.dispatch("clearEventGroupUsers")
    },
    clearUser() {
      this.filteredUser = null
    },
    showShiftFilter() {
      this.isShowShiftFilter = true
      this.isShowDateFilter = false
      this.isShowUserFilter = false
      this.isShowAddRows = false
      this.isHidden = false
      this.isVisible = true
    },
    hideShiftFilter() {
      this.isShowShiftFilter = false
    },
    clearShift() {
      this.filteredShift = null
    },
    showDateFilter() {
      this.isShowDateFilter = true
      this.isShowShiftFilter = false
      this.isShowUserFilter = false
      this.isShowAddRows = false
      this.isHidden = false
      this.isVisible = true
    },
    hideDateFilter() {
      this.isShowDateFilter = false
    },
    clearDates() {
      this.startDate = null
      this.endDate = null
    },
    showAddRows() {
      if (!this.eventGroupUsers || this.eventGroupUsers.length == 0) {
        this.$store.dispatch("getEventGroupUsers", this.$route.params.id)
      }
      this.isShowAddRows = true
      this.isShowDateFilter = false
      this.isShowShiftFilter = false
      this.isHidden = false
      this.isVisible = true
    },
    hideAddRows() {
      this.isShowAddRows = false
    },
    showHiddenPerm() {
      this.isShowDateFilter = false
      this.isShowAddRows = false
      this.isHidden = true
      this.isVisible = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showHidden() {
      this.isShowDateFilter = false
      this.isShowAddRows = false
      this.isHidden = true
      this.isVisible = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showVisiblePerm() {
      this.isVisible = true
      this.isHidden = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showVisible() {
      this.isVisible = true
      this.isHidden = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showPaid() {
      this.isPaid = true
      this.isVisible = false
      this.isHidden = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showPaidPerm() {
      this.isPaid = true
      this.isVisible = false
      this.isHidden = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showHiddenByDay() {
      if (this.activeDay) {
        this.isHiddenByDay = true
        this.isVisibleByDay = false
        this.isPaidByDay = false
        this.isHidden = false
        this.isVisible = false
        this.isPaid = false
      }
    },
    showVisibleByDay() {
      if (this.activeDay) {
        this.isVisibleByDay = true
        this.isHiddenByDay = false
        this.isPaidByDay = false
        this.isHidden = false
        this.isVisible = false
        this.isPaid = false
      }
    },
    showPaidByDay() {
      if (this.activeDay) {
        this.isPaidByDay = true
        this.isVisibleByDay = false
        this.isHiddenByDay = false
        this.isHidden = false
        this.isVisible = false
        this.isPaid = false
      }
    },
  },
  beforeDestroy () {
    // this.$store.dispatch('clearEventUsersAndRef')
    this.$store.dispatch("clearEventAssignmentsByDay")
    this.$store.dispatch("clearEventAssignments")
    this.performingRequest = null
    delete this.performingRequest
    this.isVisible = null
    delete this.isVisible
    this.isHidden = null
    delete this.isHidden
    this.isVisibleByDay = null
    delete this.isVisibleByDay
    this.isHiddenByDay = null
    delete this.isHiddenByDay
    this.isPaid = null
    delete this.isPaid
    this.isPaidByDay = null
    delete this.isPaidByDay
    this.activeItem = null
    delete this.activeItem
    this.activeDay = null
    delete this.activeDay
    delete this.setStartDay
    delete this.updateDay
    delete this.showHidden
    delete this.showVisible
    delete this.showPaid
    delete this.showHiddenByDay
    delete this.showVisibleByDay
    delete this.showPaidByDay
    // delete this.formatDate
    delete this.exportRegisterByDay
    delete this.exportRegister
    delete this.visibleAssignmentsByDay
    delete this.hiddenAssignmentsByDay
    delete this.paidAssignmentsByDay
    this.newShiftDate = null
    delete this.newShiftDate
    this.shiftDates = null
    delete this.shiftDates
    this.isShowAddRows = null
    delete this.isShowAddRows
    this.isShowDateFilter = null
    delete this.isShowDateFilter
    this.isShowShiftFilter = null
    delete this.isShowShiftFilter
    this.isShowUserFilter = null
    delete this.isShowUserFilter
    this.endDate = null
    delete this.endDate
    this.startDate = null
    delete this.startDate
    this.filteredShift = null
    delete this.filteredShift
    this.filteredUser = null
    delete this.filteredUser
    delete this.permShifts
    this.newRow = null
    delete this.newRow
    console.log(this)
  },
  errorCaptured(err, vm, info) {
    // Handle errors here
    console.error('Error Captured Hook:', err, vm, info);
  },
}
</script>